import apiXmentor from "../server/apiServer";

interface OAuth2GoogleRequest {
  id: string;
  token: string;
}

interface OAuth2GoogleResponse {
  msg: "ok";
  id: string;
  token: string;
}

interface OAuth2GoogleResponseError {
  msg: "error";
  code: string | number;
}

type IDataProps = {
  dateTime: string;
  timeZone: string;
};

type IRemindersProps = {
  useDefault: boolean;
  overrides: { method: string; minutes: number };
};

export interface ICreateCalendar {
  summary: string;
  location: string;
  description: string;
  start: IDataProps;
  end: IDataProps;
  recurrence?: string[];
  attendees: { email: string }[];
  reminders?: IRemindersProps;
}

export interface ICreateCalendarRequest extends ICreateCalendar {
  userId: string;
}

export interface UserInfoResponse {
  msg: string;
  userInfo: {
    email: string;
    name?: string;
    picture?: string;
  };
}

export interface UserInfoErrorResponse {
  msg: string;
  error?: string;
}

export async function getOAuthURLAPI(): Promise<string> {
  const response = await apiXmentor.get(`/google/authoa2`);
  return response.data;
}

export async function sendOAuthTokenAPI(
  data: OAuth2GoogleRequest
): Promise<OAuth2GoogleResponse | OAuth2GoogleResponseError> {
  console.log(data);
  return (await apiXmentor.patch(`/google/authoa2`, data)).data;
}

export async function createMeetAPI(
  data: ICreateCalendar
): Promise<OAuth2GoogleResponse | OAuth2GoogleResponseError> {
  return (await apiXmentor.patch(`/google/authoa2`, data)).data;
}

export async function getUserInfoAPI(
  id: string
): Promise<UserInfoResponse | UserInfoErrorResponse> {
  try {
    const response = await apiXmentor.post(`/google/userinfo`, { id });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar as informações do usuário:", error);
    return {
      msg: "error",
      error: "Erro ao buscar as informações do usuário.",
    };
  }
}

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getMentorByIdAPI,
  IMentorAgendamento,
  deleteAgendaAPI,
  createAgendaAPI,
} from "../../../helpers/agendaHelper";
import {
  Spinner,
  Center,
  Box,
  VStack,
  useToast,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { MentorDetails } from "./sections/mentorDetails";
import { TemplateAdmin } from "../../../components/layout/admin/TemplateAdmin";
import ScheduleModal from "./sections/ScheduleModal";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useAuth } from "../../../context/AuthProvider/useAuth";

interface IError {
  msg: string;
  error: string;
}

export function MentorDetailsPage() {
  const { payLoad } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [mentorData, setMentorData] = useState<IMentorAgendamento | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<IError | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMentorData = async () => {
      if (!id) return setLoading(false);

      try {
        const data = await getMentorByIdAPI(id);
        "error" in data
          ? setError(data as IError)
          : setMentorData(data as IMentorAgendamento);
      } catch (err) {
        setError({
          msg: "Erro",
          error: err instanceof Error ? err.message : "Erro desconhecido",
        });
      } finally {
        setLoading(false);
      }
    };
    fetchMentorData();
  }, [id]);

  const showToast = (message: string, status: "success" | "error") => {
    toast({
      position: "top",
      description: message,
      status,
      duration: 2000,
      isClosable: true,
    });
  };

  const handleRemoveAppointment = async (date: string) => {
    if (!mentorData) return;

    try {
      const agendamentos = mentorData.agendamentos[date];
      const idsToRemove = agendamentos.map((e) => e.id);
      const result = await deleteAgendaAPI(idsToRemove);

      if (result.msg === "ok") {
        // Recarrega os dados do mentor após a remoção dos horários
        const updatedMentorData = await getMentorByIdAPI(id || "");
        if (!("error" in updatedMentorData)) {
          setMentorData(updatedMentorData as IMentorAgendamento);
          showToast("Agendamento removido com sucesso!", "success");
        } else {
          throw new Error("Erro ao carregar dados atualizados do mentor");
        }
      }
    } catch (error) {
      console.error("Erro ao remover agendamento:", error);
      showToast("Erro ao remover agendamento.", "error");
    }
  };

  const convertToDisplayFormat = (dateString: string) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const addAppointment = async () => {
    setLoading(true);
    try {
      const date = convertToDisplayFormat(selectedDate);
      const agenda = mentorData?.agendamentos[date] || [];
      const oldTimes = agenda.map((e) => e.time);
      const timesToAdd = selectedTimes.filter(
        (time) => !oldTimes.includes(time)
      );

      if (timesToAdd.length && mentorData) {
        const resultAdd = await createAgendaAPI({
          user_id: payLoad?.user.id || "",
          agendamento: [
            { data: date, time: timesToAdd, mentor: mentorData.id },
          ],
        });
        if (resultAdd.msg === "ok") {
          const updatedAgendamentos = {
            ...mentorData.agendamentos,
            [date]: [
              ...agenda,
              ...timesToAdd.map((time) => ({ id: "", time, link: "" })),
            ],
          };
          setMentorData({ ...mentorData, agendamentos: updatedAgendamentos });
          showToast("Horários adicionados com sucesso!", "success");
        } else {
          throw new Error("Erro ao adicionar horários");
        }
      }
    } catch {
      showToast("Erro ao adicionar os horários.", "error");
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const handleCalendarDateSelection = (date: Date) => {
    const formattedDate = date.toISOString().split("T")[0];
    setSelectedDate(formattedDate);

    // Converte a data para o formato "DD/MM/YYYY" usado no mentorData
    const displayDate = convertToDisplayFormat(formattedDate);
    const timesForSelectedDate = mentorData?.agendamentos[displayDate]
      ? mentorData.agendamentos[displayDate].map((slot) => slot.time)
      : [];

    setSelectedTimes(timesForSelectedDate);
    onOpen();
  };

  if (error) {
    toast({
      position: "top",
      description: error.msg,
      status: "error",
      duration: 2000,
      isClosable: true,
    });
  }

  return mentorData ? (
    <TemplateAdmin>
      <VStack align={"start"} my={"15px"} spacing={4} fontWeight={"bold"}>
        <Button
          px={0}
          variant={"ghost"}
          leftIcon={<IoIosArrowRoundBack size={30} />}
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>
        {!isLoading ? (
          <Box
            px={5}
            border={"1px"}
            borderColor={"xmentor.borderCards"}
            borderRadius={"8px"}
            py={4}
            bgColor={"xmentor.background_content"}
            w={"100%"}
            alignItems={"start"}
            minH={"500px"}
          >
            <MentorDetails
              mentorData={mentorData}
              isLoading={isLoading}
              handleRemoveAppointment={handleRemoveAppointment}
              handleCalendarDateClick={handleCalendarDateSelection}
              onOpen={onOpen}
            />
          </Box>
        ) : (
          <Center>
            <Spinner />
          </Center>
        )}
      </VStack>

      <ScheduleModal
        isOpen={isOpen}
        onClose={onClose}
        selectedDate={selectedDate}
        selectedTimes={selectedTimes}
        handleSelecteData={setSelectedDate}
        handleAddTimeInput={() => setSelectedTimes([...selectedTimes, ""])}
        handleRemoveTimeInput={(index) => {
          const updatedTimes = [...selectedTimes];
          updatedTimes.splice(index, 1);
          setSelectedTimes(updatedTimes);
        }}
        handleAddAppointment={addAppointment}
        handleUpdateTime={setSelectedTimes}
      />
    </TemplateAdmin>
  ) : (
    <Center>Nenhum dado encontrado</Center>
  );
}

import React, { useEffect, useRef, useState } from "react";
import {
  VStack,
  HStack,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spinner,
  Center,
  Button,
  Avatar,
  IconButton,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import {
  getAgendamentos,
  updateAgendaAPI,
  cancelAgendaAPI,
} from "../../../../helpers/agendaHelper";
import { MdEdit } from "react-icons/md";
import ModalEditReuniao from "../../../../components/ModalEditReuniao"; // Assumindo que o modal está no mesmo diretório
import { Search } from "../../../../components/Search";
import { IoClose } from "react-icons/io5";

export interface Participante {
  id: string;
  nome: string;
  foto: string;
  email: string;
  perfil: "mentorado" | "mentor";
  responseStatus: "accepted" | "declined" | "tentative" | "needsAction";
  isOrganizer: boolean;
}

interface Participacao {
  participante: Participante;
}

export interface Reuniao {
  id: string;
  tituloReuniao: string;
  descricaoReuniao: string;
  time: string;
  data: string;
  link: string;
  data_cadastro: string;
  fusoHorario: string;
  created_by: string;
  isOpen: boolean;
  participacoes: Participacao[];
  inicioEvento: string;
  fimEvento: string;
}

export const Agendamentos: React.FC = () => {
  const { payLoad } = useAuth();
  const toast = useToast();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [reunioes, setReunioes] = useState<Reuniao[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [mentorFilter, setMentorFilter] = useState<string>("");
  const [mentoradoFilter, setMentoradoFilter] = useState<string>("");
  const [dateFilter, setDateFilter] = useState<string>("");
  const [filteredReunioes, setFilteredReunioes] = useState<Reuniao[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingCancel, setIsLoadingCancel] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // Estado para controlar o modal
  const [reuniaoSelecionada, setReuniaoSelecionada] = useState<Reuniao | null>(
    null
  ); // Reunião selecionada para editar

  useEffect(() => {
    if (payLoad?.user.mentoria_id) {
      agendamentos(payLoad.user.mentoria_id);
    }
  }, [payLoad]);

  async function agendamentos(mentoria_id: string) {
    try {
      setIsLoading(true);
      const agendamentos = await getAgendamentos(
        mentoria_id,
        payLoad?.user.id || ""
      );
      setReunioes(agendamentos);
      setFilteredReunioes(agendamentos);
    } catch (error) {
      console.error("Erro ao carregar agendamentos:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const term = searchTerm.trim().toLowerCase();

    const filtered = reunioes.filter((reuniao) => {
      const mentorMatch = mentorFilter
        ? reuniao.participacoes.some(
            (p) =>
              p.participante &&
              p.participante.perfil === "mentor" &&
              p.participante.nome &&
              p.participante.nome
                .toLowerCase()
                .includes(mentorFilter.toLowerCase())
          )
        : true;

      const mentoradoMatch = mentoradoFilter
        ? reuniao.participacoes.some(
            (p) =>
              p.participante &&
              p.participante.perfil === "mentorado" &&
              p.participante.nome &&
              p.participante.nome
                .toLowerCase()
                .includes(mentoradoFilter.toLowerCase())
          )
        : true;

      const dateMatch = dateFilter
        ? reuniao.data === convertData(dateFilter)
        : true;

      const searchTermMatch = term
        ? reuniao.data.includes(term) ||
          reuniao.time.includes(term) ||
          reuniao.link.includes(term) ||
          reuniao.participacoes.some(
            (p) =>
              p.participante &&
              p.participante.nome &&
              p.participante.nome.toLowerCase().includes(term)
          )
        : true;

      return mentorMatch && mentoradoMatch && dateMatch && searchTermMatch;
    });

    setFilteredReunioes(filtered);
  }, [searchTerm, mentorFilter, mentoradoFilter, dateFilter, reunioes]);

  const convertData = (dateString: string) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  // Função para converter ISO para 'yyyy-mm-dd' e 'HH:MM'
  const extractHour = (isoString: string) => {
    const time = isoString.toString().split("T")[1].slice(0, 5); // 'HH:MM'
    return time;
  };

  const getUniqueParticipants = (perfil: "mentor" | "mentorado") => {
    const participants = new Set<string>();
    reunioes.forEach((reuniao) => {
      reuniao.participacoes
        .filter((p) => p.participante.perfil === perfil)
        .forEach((p) => participants.add(p.participante.nome));
    });
    return Array.from(participants);
  };

  const handleOpenMeeting = (link: string) => {
    window.open(link, "_blank");
  };

  const openModal = (reuniao: Reuniao) => {
    setReuniaoSelecionada(reuniao);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setReuniaoSelecionada(null);
  };

  // Função para lidar com o salvamento da edição da reunião
  const handleSave = async (dadosReuniao: any) => {
    if (reuniaoSelecionada) {
      try {
        const { id } = reuniaoSelecionada;

        // Chamada para a API para atualizar a reunião
        await updateAgendaAPI(id, dadosReuniao);

        // Recarrega os agendamentos para garantir que estejam atualizados
        await agendamentos(payLoad?.user.mentoria_id || "");

        // Atualizando o estado local após a edição bem-sucedida
        setReunioes((prevReunioes) =>
          prevReunioes.map((reuniao) =>
            reuniao.id === id ? { ...reuniao, ...dadosReuniao } : reuniao
          )
        );

        toast({
          position: "top",
          description: `Reunião atualizada com sucesso!`,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Erro ao salvar a reunião:", error);
        toast({
          position: "top",
          description: `Ocorreu um erro ao salvar a reunião`,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  // Função para cancelar a reunião
  const handleCancelAgendamento = async () => {
    setIsLoadingCancel(true);
    if (reuniaoSelecionada) {
      try {
        const response = await cancelAgendaAPI(reuniaoSelecionada.id);

        // Verificação da resposta bem-sucedida
        if (response?.msg === "ok") {
          // Exibe o toast de sucesso
          toast({
            position: "top",
            description: "Reunião cancelada com sucesso.",
            status: "success",
            duration: 2000,
            isClosable: true,
          });

          // Atualiza a lista de reuniões, removendo o item cancelado
          setReunioes((prevReunioes) =>
            prevReunioes.filter(
              (reuniao) => reuniao.id !== reuniaoSelecionada.id
            )
          );

          // Fecha o modal de confirmação
          setIsDeleteModalOpen(false);
        } else {
          // Exibe o toast de erro se a resposta não for "ok"
          toast({
            position: "top",
            description: "Erro ao cancelar a reunião. Tente novamente.",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Erro ao cancelar a reunião:", error);

        // Exibe o toast de erro se a requisição falhar
        toast({
          position: "top",
          description: "Erro ao cancelar a reunião.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } finally {
        // Garante que o modal será fechado após a tentativa de cancelamento
        setIsDeleteModalOpen(false);
      }
    }
    setIsLoadingCancel(false);
  };

  // Função para abrir o modal de confirmação de cancelamento
  const openDeleteModal = (reuniao: Reuniao) => {
    setReuniaoSelecionada(reuniao);
    setIsDeleteModalOpen(true);
  };

  // Função para fechar o modal de confirmação de cancelamento
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setReuniaoSelecionada(null);
  };

  return (
    <VStack align={"start"} spacing={4} fontWeight={"bold"} w={"100%"}>
      <HStack
        w="100%"
        justifyContent="space-between"
        flexWrap={"wrap"}
        alignItems="center"
      >
        {/* Campo de pesquisa */}
        <Search search={searchTerm} setSearch={setSearchTerm} />

        <HStack
          spacing={{ base: 2, md: 4 }}
          flexWrap={{ base: "wrap", md: "nowrap" }}
          alignItems="center"
        >
          {/* Filtro de data */}
          <Input
            type="date"
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
            size="md"
            w="160px"
            h="56px"
            bg="#FFF"
            border="1px solid #EAECF0"
            textColor="gray.500"
          />

          {/* Filtro de mentor */}
          <Select
            placeholder="Mentor"
            value={mentorFilter}
            onChange={(e) => setMentorFilter(e.target.value)}
            size="md"
            w="160px"
            h="56px"
            bg="#FFF"
            border="1px solid #EAECF0"
            textColor="gray.500"
          >
            {getUniqueParticipants("mentor").map((mentor) => (
              <option key={mentor} value={mentor}>
                {mentor}
              </option>
            ))}
          </Select>

          {/* Filtro de mentorado */}
          <Select
            placeholder="Mentorado"
            value={mentoradoFilter}
            onChange={(e) => setMentoradoFilter(e.target.value)}
            size="md"
            w="160px"
            h="56px"
            bg="#FFF"
            border="1px solid #EAECF0"
            textColor="gray.500"
          >
            {getUniqueParticipants("mentorado").map((mentorado) => (
              <option key={mentorado} value={mentorado}>
                {mentorado}
              </option>
            ))}
          </Select>
        </HStack>
      </HStack>

      <Box
        bg="#FFF"
        borderRadius="8px"
        border="1px solid #EAECF0"
        w={"100%"}
        overflowX={"auto"}
        minWidth={"700px"}
      >
        {isLoading ? (
          <Center w="100%" h="200px">
            <Spinner color="xmentor.primary" size="lg" />
          </Center>
        ) : (
          <Table variant="simple" w="full" minWidth={"700px"}>
            <Thead>
              <Tr>
                <Th textTransform="none">Data/Hora</Th>
                <Th textTransform="none">Título</Th>
                <Th textTransform="none">Mentor</Th>
                <Th textTransform="none">Mentorado</Th>
                <Th textTransform="none">Link da reunião</Th>
                <Th textTransform="none" pl={1}>
                  Ação
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredReunioes.filter((reuniao) => !reuniao.isOpen).length >
              0 ? (
                filteredReunioes
                  .filter((reuniao) => !reuniao.isOpen)
                  .map((reuniao) => {
                    const mentor = reuniao.participacoes.find((p) =>
                      p.participante.isOrganizer
                        ? p.participante.isOrganizer
                        : p.participante.perfil === "mentor"
                    )?.participante;
                    const mentorado = reuniao.participacoes.find(
                      (p) => p.participante.perfil === "mentorado"
                    )?.participante;

                    return (
                      <Tr
                        key={reuniao.id}
                        _hover={{ bg: "#F9FAFB" }}
                        fontWeight="600"
                        fontSize={"small"}
                        borderBottom={"1px solid #EAECF0"}
                      >
                        <Td border={"none"}>
                          <VStack spacing={1}>
                            <div>
                              {reuniao.data} -{" "}
                              {extractHour(reuniao.inicioEvento)}
                            </div>
                            <div>{reuniao.fusoHorario}</div>
                          </VStack>
                        </Td>
                        <Td border={"none"} px={1}>
                          {reuniao.tituloReuniao}
                        </Td>
                        <Td border={"none"} px={3}>
                          <HStack spacing={3}>
                            <Avatar
                              size="sm"
                              src={`${process.env.REACT_APP_API}/tmp/user/${mentor?.foto}`}
                              borderRadius={"md"}
                            />
                            <Box>{mentor?.nome || "Aguardando"}</Box>
                          </HStack>
                        </Td>
                        <Td border={"none"} px={3}>
                          <HStack spacing={3} rounded="md">
                            <Avatar
                              size="sm"
                              src={`${process.env.REACT_APP_API}/tmp/user/${mentorado?.foto}`}
                              borderRadius={"md"}
                            />
                            <Box>{mentorado?.nome || "Aguardando"}</Box>
                          </HStack>
                        </Td>
                        <Td border={"none"} px={2}>
                          {mentorado && (
                            <Button
                              bg={"xmentor.primary"}
                              colorScheme={"xmentor.primary"}
                              textColor={"white"}
                              fontSize={"xs"}
                              onClick={() => handleOpenMeeting(reuniao.link)}
                            >
                              Entrar com Google Meet
                            </Button>
                          )}
                        </Td>
                        <Td pl={1} border="none">
                          <HStack spacing={1}>
                            <IconButton
                              aria-label="Edit"
                              textColor="gray.500"
                              icon={<MdEdit size={18} />}
                              size="sm"
                              variant="outline"
                              onClick={() => openModal(reuniao)}
                              flex={1}
                            />
                            <IconButton
                              aria-label="Delete"
                              color="red"
                              icon={<IoClose color="red" size={18} />}
                              size="sm"
                              variant="outline"
                              onClick={() => openDeleteModal(reuniao)}
                              flex={1}
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    );
                  })
              ) : (
                <Tr>
                  <Td colSpan={6} textAlign="center">
                    Nenhuma reunião encontrada.
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        )}
      </Box>

      {/* Modal para confirmar cancelamento */}
      <AlertDialog
        isOpen={isDeleteModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeDeleteModal}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancelar Reunião
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza de que deseja cancelar esta reunião? Esta ação não
              pode ser desfeita.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeDeleteModal}>
                Não
              </Button>
              <Button
                colorScheme="red"
                onClick={handleCancelAgendamento}
                isLoading={isLoadingCancel}
                ml={3}
              >
                Sim, cancelar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Modal para editar reunião */}
      {reuniaoSelecionada && (
        <ModalEditReuniao
          isOpen={isModalOpen}
          onClose={closeModal}
          reuniao={reuniaoSelecionada}
          onSave={handleSave}
        />
      )}
    </VStack>
  );
};
